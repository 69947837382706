import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import Admin from "./pages/Admin";
import Specials from "./pages/Specials";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Specials />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/specials" element={<Specials />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
