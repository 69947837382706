import React, { useState, useEffect } from "react";
import {
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import { storage } from "../firebase";
import Button from "@mui/material/Button";

function ImageList({ shouldFetchImages, setShouldFetchImages }) {
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentFileToDelete, setCurrentFileToDelete] = useState(null);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fetchFiles = () => {
    const imageListRef = ref(storage, "images/");
    const pdfListRef = ref(storage, "pdfs/");
    const listRefs = [imageListRef, pdfListRef];

    Promise.all(
      listRefs.map((listRef) =>
        listAll(listRef).then((res) => {
          const promises = res.items.map((itemRef) => {
            return Promise.all([
              getDownloadURL(itemRef),
              getMetadata(itemRef),
            ]).then(([url, metadata]) => ({
              url,
              ref: itemRef,
              type: itemRef.parent.name === "images" ? "image" : "pdf",
              timeCreated: metadata.timeCreated,
            }));
          });
          return Promise.all(promises);
        })
      )
    )
      .then((results) => {
        const files = results.flat();
        // Sort the files by timeCreated in descending order
        const sortedFiles = files.sort(
          (a, b) => new Date(b.timeCreated) - new Date(a.timeCreated)
        );
        setFiles(sortedFiles);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const promptDelete = (fileRef) => {
    setCurrentFileToDelete(fileRef);
    setShowModal(true);
  };

  const handleDelete = () => {
    if (currentFileToDelete) {
      deleteObject(currentFileToDelete)
        .then(() => {
          setFiles((prevFiles) =>
            prevFiles.filter((file) => file.ref !== currentFileToDelete)
          );
          setShowModal(false);
          setCurrentFileToDelete(null);
        })
        .catch((error) => {
          console.error(error);
          setShowModal(false);
        });
    }
  };

  useEffect(() => {
    if (shouldFetchImages) {
      fetchFiles();
      setShouldFetchImages(false);
    }
  }, [shouldFetchImages]);

  const DeleteConfirmationModal = ({ onClose, onConfirm }) => {
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h2 className="text-lg">
            Are you sure you want to delete this file?
          </h2>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={onConfirm}
            >
              Delete
            </button>
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4">
      {showModal && (
        <DeleteConfirmationModal
          onClose={() => setShowModal(false)}
          onConfirm={handleDelete}
        />
      )}
      <div className="grid grid-cols-3 gap-4">
        {files.map((file, index) => (
          <div key={index} className="relative">
            {file.type === "image" ? (
              <img
                src={file.url}
                alt={`uploaded ${index}`}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-gray-200">
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  View PDF
                </a>
              </div>
            )}
            <Button
              onClick={() => promptDelete(file.ref)}
              // className="absolute top-0 right-0 bg-sparRed text-white p-2 rounded"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "#ed2f3d",
                color: "white",
                boxShadow: 10,
                borderTopRightRadius: 0,
                "&:hover": {
                  backgroundColor: "#cd232f",
                },
              }}
            >
              Delete
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageList;
