import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            textAlign: "center",
            p: 3,
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            fontFamily="AcuminProSemibold"
          >
            Welcome to Kwikspar Wapadrand
          </Typography>
          <Typography
            variant="h5"
            component="p"
            gutterBottom
            fontFamily="AcuminProSemibold"
          >
            Discover the best deals and offers available today!
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ mb: 3 }}
            fontFamily="AcuminProSemibold"
          >
            Visit our <strong>Specials</strong> page to check out the latest
            current specials and save big on your next purchase.
          </Typography>
          <Button
            component={Link}
            to="/specials"
            variant="contained"
            color="primary"
            size="large"
            fontFamily="AcuminProSemibold"
            sx={{ fontWeight: "bold" }}
          >
            View Specials
          </Button>
        </Box>
      </main>
      <Footer />
    </div>
  );
}

export default App;
