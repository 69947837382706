import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageUpload from "../components/ImageUpload";
import ImageList from "../components/ImageList";
import Login from "../components/Login";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

function Admin() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, []);

  const [shouldFetchImages, setShouldFetchImages] = useState(true);

  const handleUploadSuccess = () => {
    setShouldFetchImages(true);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        {user ? (
          <>
            <ImageUpload onUploadSuccess={handleUploadSuccess} />
            <ImageList
              shouldFetchImages={shouldFetchImages}
              setShouldFetchImages={setShouldFetchImages}
            />
          </>
        ) : (
          <Login />
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Admin;
