import React, { useState, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

function FileUpload({ onUploadSuccess }) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType.startsWith("image/")) {
        setFile(selectedFile);
        setPreview(URL.createObjectURL(selectedFile));
        setProgress(0);
        setError("");
      } else {
        setError("Please select an image file.");
      }
    }
  };

  const handleSave = () => {
    if (!file) {
      alert("No file selected!");
      return;
    }
    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        setError(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          onUploadSuccess(downloadURL);
          setFile(null);
          setPreview(null);
          setProgress(0);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        });
      }
    );
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12}>
          <Button variant="contained" component="label" fullWidth>
            Select File
            <input
              type="file"
              hidden
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          {file && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#00833d",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#00833d" },
              }}
              onClick={handleSave}
              fullWidth
            >
              Save
            </Button>
          )}
        </Grid>
      </Grid>
      {preview && (
        <Box mt={2} display="flex" justifyContent="center">
          <img
            src={preview}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "300px", objectFit: "cover" }}
          />
        </Box>
      )}
      {progress > 0 && (
        <Box mt={2}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
      {error && (
        <Box mt={2} color="red">
          <Typography variant="body2">{error}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default FileUpload;
