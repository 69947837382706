import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-sparGreen text-white p-4 mt-auto text-center">
      <p>&copy; {currentYear} KWIKSPAR WAPADRAND</p>
    </footer>
  );
}

export default Footer;
