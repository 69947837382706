// import React, { useState, useEffect, useRef } from "react";
// import { useSwipeable } from "react-swipeable";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import { storage } from "../firebase";
// import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
// import "../index.css";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";

// function Specials() {
//   const [files, setFiles] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [showHint, setShowHint] = useState(true);
//   const hasFetchedFiles = useRef(false);

//   const [loading, setLoading] = useState(true);

//   const [isDragging, setIsDragging] = useState(false);
//   const [startY, setStartY] = useState(0);

//   useEffect(() => {
//     if (hasFetchedFiles.current) return;
//     const fetchFiles = async () => {
//       const imageListRef = ref(storage, "images/");
//       const pdfListRef = ref(storage, "pdfs/");
//       const listRefs = [imageListRef, pdfListRef];

//       const results = await Promise.all(
//         listRefs.map(async (listRef) => {
//           const res = await listAll(listRef);
//           const promises = res.items.map(async (itemRef) => {
//             const url = await getDownloadURL(itemRef);
//             const metadata = await getMetadata(itemRef);
//             return {
//               url,
//               type: itemRef.parent.name === "images" ? "image" : "pdf",
//               timeCreated: metadata.timeCreated,
//             };
//           });
//           return Promise.all(promises);
//         })
//       );

//       const allFiles = results.flat();
//       const sortedFiles = allFiles.sort(
//         (a, b) => new Date(b.timeCreated) - new Date(a.timeCreated)
//       );
//       setFiles(sortedFiles);
//       hasFetchedFiles.current = true;
//       setLoading(false);
//     };

//     fetchFiles();
//   }, []);

//   const handleSwipedUp = () => {
//     setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, files.length - 1));
//     setShowHint(false);
//   };

//   const handleSwipedDown = () => {
//     setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
//     setShowHint(false);
//   };

//   const swipeHandlers = useSwipeable({
//     onSwipedUp: handleSwipedUp,
//     onSwipedDown: handleSwipedDown,
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true, // Add this line to enable mouse tracking
//     delta: 10,
//   });

//   useEffect(() => {
//     const handleTouchMove = (e) => {
//       if (e.touches.length > 1 || e.defaultPrevented) {
//         return;
//       }

//       const touch = e.touches[0];
//       if (touch.clientY < 0 || touch.clientY > window.innerHeight) {
//         e.preventDefault();
//       }
//     };

//     const handleMouseDown = (e) => {
//       setIsDragging(true);
//       setStartY(e.clientY);
//     };

//     const handleMouseMove = (e) => {
//       if (!isDragging) return;

//       const currentY = e.clientY;
//       const diffY = currentY - startY;

//       if (diffY > 50) {
//         handleSwipedDown();
//         setIsDragging(false);
//       } else if (diffY < -50) {
//         handleSwipedUp();
//         setIsDragging(false);
//       }
//     };

//     const handleMouseUp = () => {
//       setIsDragging(false);
//     };

//     document.addEventListener("touchmove", handleTouchMove, { passive: false });
//     document.addEventListener("mousedown", handleMouseDown);
//     document.addEventListener("mousemove", handleMouseMove);
//     document.addEventListener("mouseup", handleMouseUp);

//     return () => {
//       document.removeEventListener("touchmove", handleTouchMove);
//       document.removeEventListener("mousedown", handleMouseDown);
//       document.removeEventListener("mousemove", handleMouseMove);
//       document.removeEventListener("mouseup", handleMouseUp);
//     };
//   }, [isDragging, startY]);

//   const handleImageLoad = () => {
//     setLoading(false);
//   };

//   return (
//     <div className="flex flex-col min-h-screen">
//       <Header />
//       <main className="flex-grow">
//         <div className="relative min-h-screen">
//           {loading && (
//             <div className="absolute inset-0 flex items-center justify-center bg-white z-50">
//               <div className="text-center">
//                 <div className="loader"></div>
//                 <Typography
//                   variant="body1"
//                   sx={{
//                     color: "#000000",
//                     fontWeight: "normal",
//                     fontFamily: "AcuminProSemibold",
//                     display: "block",
//                     marginBottom: "16px",
//                   }}
//                 >
//                   Loading...
//                 </Typography>
//               </div>
//             </div>
//           )}
//           {files.length > 0 && (
//             <div className="absolute inset-0">
//               {files.map((file, index) => (
//                 <div
//                   key={index}
//                   className={`absolute inset-0 transition-opacity duration-500 max-w-xl mx-auto ${
//                     index === currentIndex ? "opacity-100" : "opacity-0"
//                   }`}
//                 >
//                   {file.type === "image" ? (
//                     <img
//                       src={file.url}
//                       alt={`Special ${index}`}
//                       className="w-ninety ml-ten mt-10"
//                       {...swipeHandlers}
//                       loading="lazy"
//                       onLoad={handleImageLoad}
//                     />
//                   ) : (
//                     <div className="w-full h-full flex items-center justify-center bg-gray-200">
//                       <iframe
//                         src={file.url}
//                         title={`Special ${index}`}
//                         className="w-full h-full"
//                       ></iframe>
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//         {showHint && (
//           <Box
//             sx={{
//               position: "absolute",
//               bottom: 20,
//               left: "50%",
//               transform: "translateX(-50%)",
//               textAlign: "center",
//               zIndex: 10,
//               animation: "fadeIn 1s ease-in-out",
//             }}
//           >
//             {/* <Typography
//               variant="body1"
//               sx={{
//                 color: "#000000",
//                 fontWeight: "normal",
//                 fontFamily: "AcuminProSemibold",
//                 mb: 1,
//               }}
//             >
//               Swipe up or down to see more specials
//             </Typography> */}
//             <div className="arrowContainer">
//               <div className="arrow bounce"></div>
//             </div>
//           </Box>
//         )}
//       </main>
//       <Footer />
//     </div>
//   );
// }

// export default Specials;

import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { storage } from "../firebase";
import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import "../index.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Specials() {
  const [files, setFiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showHint, setShowHint] = useState(true);
  const hasFetchedFiles = useRef(false);

  const [loading, setLoading] = useState(true);

  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);

  useEffect(() => {
    if (hasFetchedFiles.current) return;
    const fetchFiles = async () => {
      const imageListRef = ref(storage, "images/");
      const pdfListRef = ref(storage, "pdfs/");
      const listRefs = [imageListRef, pdfListRef];

      const results = await Promise.all(
        listRefs.map(async (listRef) => {
          const res = await listAll(listRef);
          const promises = res.items.map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            const metadata = await getMetadata(itemRef);
            return {
              url,
              type: itemRef.parent.name === "images" ? "image" : "pdf",
              timeCreated: metadata.timeCreated,
            };
          });
          return Promise.all(promises);
        })
      );

      const allFiles = results.flat();
      const sortedFiles = allFiles.sort(
        (a, b) => new Date(b.timeCreated) - new Date(a.timeCreated)
      );
      setFiles(sortedFiles);
      hasFetchedFiles.current = true;
      setLoading(false);
    };

    fetchFiles();
  }, []);

  const handleSwipedUp = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, files.length - 1));
    setShowHint(false);
  };

  const handleSwipedDown = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setShowHint(false);
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: handleSwipedUp,
    onSwipedDown: handleSwipedDown,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Add this line to enable mouse tracking
    delta: 10,
  });

  useEffect(() => {
    const handleTouchMove = (e) => {
      if (e.touches.length > 1 || e.defaultPrevented) {
        return;
      }

      const touch = e.touches[0];
      if (touch.clientY < 0 || touch.clientY > window.innerHeight) {
        e.preventDefault();
      }
    };

    const handleMouseDown = (e) => {
      setIsDragging(true);
      setStartY(e.clientY);
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const currentY = e.clientY;
      const diffY = currentY - startY;

      if (diffY > 50) {
        handleSwipedDown();
        setIsDragging(false);
      } else if (diffY < -50) {
        handleSwipedUp();
        setIsDragging(false);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startY]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <div className="relative min-h-screen">
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white z-50">
              <div className="text-center">
                <div className="loader"></div>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#000000",
                    fontWeight: "normal",
                    fontFamily: "AcuminProSemibold",
                    display: "block",
                    marginBottom: "16px",
                  }}
                >
                  Loading...
                </Typography>
              </div>
            </div>
          )}
          {files.length > 0 && (
            <div className="absolute inset-0">
              {files.map((file, index) => (
                <div
                  key={index}
                  className={`absolute inset-0 transition-opacity duration-500 max-w-xl mx-auto ${
                    index === currentIndex ? "opacity-100" : "opacity-0"
                  }`}
                >
                  {file.type === "image" ? (
                    <img
                      src={file.url}
                      alt={`Special ${index}`}
                      className="w-ninety ml-ten mt-10"
                      {...swipeHandlers}
                      style={{ touchAction: "none" }} // Add this line
                      loading="lazy"
                      onLoad={handleImageLoad}
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gray-200">
                      <iframe
                        src={file.url}
                        title={`Special ${index}`}
                        className="w-full h-full"
                      ></iframe>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {showHint && (
          <Box
            sx={{
              position: "absolute",
              bottom: 20,
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
              zIndex: 10,
              animation: "fadeIn 1s ease-in-out",
            }}
          >
            {/* <Typography
              variant="body1"
              sx={{
                color: "#000000",
                fontWeight: "normal",
                fontFamily: "AcuminProSemibold",
                mb: 1,
              }}
            >
              Swipe up or down to see more specials
            </Typography> */}
            <div className="arrowContainer">
              <div className="arrow bounce"></div>
            </div>
          </Box>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Specials;
