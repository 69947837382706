import React from "react";
import { Link } from "react-router-dom";
import logo from "../logos.jpg";
import kwiksparlogo from "../kwikspar-logo.jpg";
import pharmacylogo from "../pharmacy-logo.jpg";
import topslogo from "../tops-logo.jpg";
import headerText from "../header-text.jpg";

function Header() {
  return (
    <header className="bg-sparRed text-white p-4 flex flex-col items-center justify-center">
      <div className="hidden md:flex flex-col items-center justify-center space-y-4">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-10" />
        </Link>
        <img src={headerText} alt="headerText" className="h-16" />
      </div>
      <div className="md:hidden flex space-x-4">
        <Link to="/">
          <img src={kwiksparlogo} alt="Kwikspar Wapadrand" className="h-6" />
        </Link>
        <Link to="/">
          <img src={pharmacylogo} alt="Spar Pharmacy" className="h-6" />
        </Link>
        <Link to="/">
          <img src={topslogo} alt="Spar Tops" className="h-6" />
        </Link>
      </div>
      <div style={{ paddingTop: "20px" }}></div>
      <div className="md:hidden flex space-x-4">
        <Link to="/">
          <img src={headerText} alt="headerText" className="h-12" />
        </Link>
      </div>
    </header>
  );
}

export default Header;
