import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase';

function Login() {
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <button onClick={signInWithGoogle} className="bg-blue-500 text-white p-4 rounded">
        Sign in with Google
      </button>
    </div>
  );
}

export default Login;
